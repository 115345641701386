import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quantity"];

  connect() {
    window.dataLayer = window.dataLayer || [];
  }

  addToCart() {
    let quantity = 1;

    if (this.hasQuantityTarget && this.quantityTarget instanceof HTMLInputElement) {
      quantity = +this.quantityTarget.value || 1;
    }

    const item_name = this.element.dataset.itemName;
    const item_id = this.element.dataset.itemId;
    const item_category = this.element.dataset.itemCategory;

    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [
          {
            item_name,
            item_id,
            item_category,
            quantity
          }
        ]
      }
    });
  }
}
