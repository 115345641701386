import {Controller} from "@hotwired/stimulus"
import {Subject} from 'rxjs';

// Connects to data-controller="measurement"
export default class extends Controller {
  static targets = ["toggles", "units"];

  connect() {
    this.currentUnit = new Subject();
    this.currentUnit.next('inch');
    this.currentUnit.subscribe((next) => this.onUnitChange(next));
  }

  togglesTargetConnected(target) {
    target.addEventListener('click', (e) => {
      e.preventDefault();

      if (["cm", "inch"].includes(e.target.dataset.value)) {
        this.currentUnit.next(e.target.dataset.value);
      }
    });
  }

  onUnitChange(newUnit) {
    this.togglesTargets.forEach((toggle) => {
      if (toggle.dataset.value !== newUnit) {
        toggle.classList.remove("active")
      } else {
        toggle.classList.add("active")
      }
    });

    this.unitsTargets.forEach((unit) => {
      let unitValue = unit.dataset[newUnit + "Value"]
      unit.innerText = newUnit === "inch" ? unitValue.replace(',', '.') + '"' : unitValue.replace('.', ',') + " cm";
    });
  }
}
